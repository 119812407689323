import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'FairCode - Creative Agency',
      metaTags: [
        {
          name: 'description',
          content: 'FairCode provides innovative, pixel-perfect solutions to businesses of all sizes to help their brands be different from the crowd.'
        },
        {
          name: 'format-detection',
          content: 'telephone=no'
        },
        {
          name: 'og:title',
          content: 'FairCode - Creative Agency'
        },
        {
          name: 'og:description',
          content: 'FairCode provides innovative, pixel-perfect solutions to businesses of all sizes to help their brands be different from the crowd.'
        },
        {
          name: 'og:url',
          content: 'https://faircode.tech'
        },
        {
          name: 'og:site_name',
          content: 'FairCode'
        },
        {
          name: 'og:type',
          content: 'website'
        }
      ]
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/about/About'),
    meta: {
      title: 'About | FairCode',
      metaTags: [
        {
          name: 'description',
          content: 'We design creative solutions that engage your customers.'
        },
        {
          name: 'format-detection',
          content: 'telephone=no'
        },
        {
          name: 'og:title',
          content: 'About | FairCode'
        },
        {
          name: 'og:description',
          content: 'We design creative solutions that engage your customers.'
        },
        {
          name: 'og:url',
          content: 'https://faircode.tech/about'
        },
        {
          name: 'og:site_name',
          content: 'FairCode'
        },
        {
          name: 'og:type',
          content: 'website'
        }
      ]
    }
  },
  {
    path: '/services',
    name: 'services',
    component: () => import(/* webpackChunkName: "about" */ '../views/services/Services'),
    meta: {
      title: 'Services | FairCode',
      metaTags: [
        {
          name: 'description',
          content: 'We provide a complete workflow from idea to production deployment.'
        },
        {
          name: 'format-detection',
          content: 'telephone=no'
        },
        {
          name: 'og:title',
          content: 'Services | FairCode'
        },
        {
          name: 'og:description',
          content: 'We provide a complete workflow from idea to production deployment.'
        },
        {
          name: 'og:url',
          content: 'https://faircode.tech/services'
        },
        {
          name: 'og:site_name',
          content: 'FairCode'
        },
        {
          name: 'og:type',
          content: 'website'
        }
      ]
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/contact/Contact'),
    meta: {
      title: 'Contact | FairCode',
      metaTags: [
        {
          name: 'description',
          content: 'Let\'s discuss your project.'
        },
        {
          name: 'format-detection',
          content: 'telephone=no'
        },
        {
          name: 'og:title',
          content: 'Contact | FairCode'
        },
        {
          name: 'og:description',
          content: 'Let\'s discuss your project.'
        },
        {
          name: 'og:url',
          content: 'https://faircode.tech/contact'
        },
        {
          name: 'og:site_name',
          content: 'FairCode'
        },
        {
          name: 'og:type',
          content: 'website'
        }
      ]
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/privacy/Privacy'),
    meta: {
      title: 'Privacy | FairCode',
      metaTags: [
        {
          name: 'description',
          content: 'This privacy policy applies to our use of any and all Data collected by us or provided by you in relation to your use of the Website. Please read this privacy policy carefully.'
        },
        {
          name: 'format-detection',
          content: 'telephone=no'
        },
        {
          name: 'og:title',
          content: 'Privacy | FairCode'
        },
        {
          name: 'og:description',
          content: 'This privacy policy applies to our use of any and all Data collected by us or provided by you in relation to your use of the Website. Please read this privacy policy carefully.'
        },
        {
          name: 'og:url',
          content: 'https://faircode.tech/privacy'
        },
        {
          name: 'og:site_name',
          content: 'FairCode'
        },
        {
          name: 'og:type',
          content: 'website'
        }
      ]
    }
  },
  {
    path: '/client',
    name: 'auth',
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/Auth')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  if (!nearestWithMeta) return next()

  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  }).forEach(tag => document.head.appendChild(tag))

  next()
})

export default router
