<template>
  <div class="bottom_panel">
    <div class="bottom_panel--container">
      <div class="global--title_container">
        <div class="global--title global--title-small">Contact</div>
        <div class="global--title_border"></div>
        <div class="global--animation_overlay global--animation_delay-basic" v-in-viewport.once></div>
      </div>
      <div class="global--animation_container">
        <div class="global--title global--title-middle">We'd love to work<br>together</div>
        <div class="global--animation_overlay" v-in-viewport.once></div>
      </div>
      <div class="bottom_panel--menu_holder">
        <div class="bottom_panel--menu_container">
          <div class="global--animation_container">
            <a class="bottom_panel--menu_link" href="/about">About</a>
            <a class="bottom_panel--menu_link" href="/services">Services</a>
            <a class="bottom_panel--menu_link bottom_panel--menu_link-disabled" href="/client">Client Access</a>
            <div class="global--animation_overlay" v-in-viewport.once></div>
          </div>
        </div>
        <div class="bottom_panel--menu_container">
          <div class="global--animation_container">
            <a class="bottom_panel--menu_link" href="/contact">Start a project</a>
            <a class="bottom_panel--menu_link" href="mailto:hello@faircode.tech">hello@faircode.tech</a>
            <div class="global--animation_overlay" v-in-viewport.once></div>
          </div>
        </div>
      </div>
      <div class="bottom_panel--footer global--animation_reveal" v-in-viewport.once>
        <div class="bottom_panel--footer_text">© Copyright 2021 FairCode Ltd. Registered in England and Wales, no. 11382758</div>
        <div class="bottom_panel--footer_link_container">
          <a class="bottom_panel--footer_link" href="/privacy">Privacy Policy</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomPanel'
}
</script>

<style lang="scss" scoped>
  @import './bottom_panel';
</style>
