<template>
  <div class="cookie_panel" v-if="!isClosed">
    <div class="cookie_panel--container">
      <div class="cookie_panel--holder">
        <div class="cookie_panel--text_container">
          <div class="cookie_panel--text">By using FairCode's services and this website you agree to our <a class="cookie_panel--link" href="/privacy">Privacy Policy</a>. We operate globally and use cookies, including for marketing and personalisation.</div>
        </div>
        <div class="cookie_panel--button_container">
          <div class="cookie_panel--button_holder" @click="closeNotice">
            <div class="cookie_panel--button"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CookiePanel',
  data () {
    return {
      isClosed: false
    }
  },
  methods: {
    closeNotice () {
      const date = new Date()
      let expires = ''

      date.setTime(date.getTime() + (180 * 24 * 60 * 60 * 1000))
      expires = '; expires=' + date.toUTCString()

      document.cookie = 'cookie_closed' + '=' + 'true' + expires + '; path=/'
      this.isClosed = true
    },
    getCookie (name) {
      const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
      if (match) return match[2]
    }
  },
  created () {
    const isClosedCookie = this.getCookie('cookie_closed')

    if (isClosedCookie) {
      this.isClosed = true
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './cookie_panel';
</style>
