import axios from 'axios'

axios.defaults.withCredentials = false

// Set environment
const apiURL = 'https://api.faircode.tech/v1'

export default axios.create({
  baseURL: apiURL,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: false
})
