<template>
  <div class="home">
    <top-panel></top-panel>
    <cookie-panel></cookie-panel>
    <div class="home--container">
      <div class="global--section">
        <div class="global--section_container">
          <div class="global--title_container">
            <div class="global--title global--title-small">The Agency</div>
            <div class="global--title_border"></div>
            <div class="global--animation_overlay global--animation_delay-basic" v-in-viewport.once></div>
          </div>
          <div class="global--title global--title-large global--animation_reveal" v-in-viewport.once>We build <br>outstanding <br>digital products</div>
          <div class="global--container global--container-right">
            <div class="global--holder">
              <div class="global--text global--animation_reveal" v-in-viewport.once>We create innovative, pixel-perfect solutions that help brands be different from the crowd.</div>
              <a class="home--start_button global--animation_reveal" v-in-viewport.once href="/contact">Hire</a>
            </div>
          </div>
        </div>
      </div>
      <div class="global--section">
        <div class="global--section_container">
          <div class="global--title_container" v-in-viewport.once>
            <div class="global--title global--title-small">Our Services</div>
            <div class="global--title_border"></div>
            <div class="global--animation_overlay global--animation_delay-basic" v-in-viewport.once></div>
          </div>
          <div class="global--animation_container">
            <div class="global--title global--title-middle">FairCode builds great products with creativity, <br>and passion, to make your brand stand out.</div>
            <div class="global--animation_overlay" v-in-viewport.once></div>
          </div>
          <div class="global--matter_holder">
            <div class="global--matter_container">
              <div class="global--matter_number">01.</div>
              <div class="global--matter_title">Plan</div>
              <div class="global--matter_text">We apply extensive research to everything we do. It is crucial to have a comprehensive plan.</div>
              <div class="global--animation_overlay" v-in-viewport.once></div>
            </div>
            <div class="global--matter_container">
              <div class="global--matter_number">02.</div>
              <div class="global--matter_title">Design</div>
              <div class="global--matter_text">We design all products with your customers in mind. They are partners in your mission.</div>
              <div class="global--animation_overlay" v-in-viewport.once></div>
            </div>
            <div class="global--matter_container">
              <div class="global--matter_number">03.</div>
              <div class="global--matter_title">Development</div>
              <div class="global--matter_text">We always follow the best practices and all industry-standard specifications.</div>
              <div class="global--animation_overlay" v-in-viewport.once></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottom-panel></bottom-panel>
  </div>
</template>

<script>
import TopPanel from '@/components/top_panel/TopPanel'
import BottomPanel from '@/components/bottom_panel/BottomPanel'
import CookiePanel from '@/components/cookie_panel/CookiePanel'

export default {
  name: 'Home',
  components: {
    TopPanel,
    BottomPanel,
    CookiePanel
  }
}
</script>

<style lang="scss" scoped>
  @import './home';
</style>
