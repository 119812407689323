import * as types from './mutation_types'
import api from '../../api'

export const sendForm = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    commit(types.CONTACT_FORM_REQUEST)

    api.post('/form', {
      services: payload.services,
      budget: payload.budget,
      firstName: payload.firstName,
      lastName: payload.lastName,
      companyName: payload.companyName,
      email: payload.email,
      deadline: payload.deadline,
      description: payload.description
    }).then(res => {
      commit(types.CONTACT_FORM_SUCCESS, res.data)
      resolve(res)
    }).catch(err => {
      commit(types.CONTACT_FORM_FAILURE, err)
      reject(err)
    })
  })
}
