import * as types from './mutation_types'

export const mutations = {
  // Contact form
  [types.CONTACT_FORM_REQUEST] (state) {
    state.isProcessing = true
  },
  [types.CONTACT_FORM_SUCCESS] (state) {
    state.isProcessing = false
  },
  [types.CONTACT_FORM_FAILURE] (state) {
    state.isProcessing = false
  }
}
