<template>
  <div class="top_panel" :class="{ 'top_panel-open': isOpen }">
    <div class="top_panel--holder">
      <div class="top_panel--container">
        <a class="top_panel--logo" :class="{ 'top_panel--logo-open': isOpen }" href="/">
          <svg viewBox="0 0 80 49" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g class="top_panel--logo_color" :class="{ 'top_panel--logo_color-open': isOpen }" transform="translate(-1713.000000, -484.000000)" fill="#ffffff">
                <g transform="translate(328.000000, 383.000000)">
                  <g transform="translate(1300.000000, 0.000000)">
                    <g transform="translate(85.000000, 81.000000)">
                      <g transform="translate(39.966163, 43.994299) rotate(-55.000000) translate(-39.966163, -43.994299) translate(3.466163, 20.994299)">
                        <g transform="translate(47.625831, 30.241758) scale(-1, -1) translate(-47.625831, -30.241758) translate(22.625831, 14.741758)">
                          <path d="M0,11.4354396 L0,0 L49.691358,0 L49.691358,30.4945055 L38.2241216,30.4945055 L38.2241216,11.4354396 L0,11.4354396 Z"></path>
                        </g>
                        <g>
                          <path d="M0,11.4354396 L0,0 L49.691358,0 L49.691358,30.4945055 L38.2241216,30.4945055 L38.2241216,11.4354396 L0,11.4354396 Z"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </a>
        <div class="top_panel--button" :class="{ 'top_panel--button-open': isOpen }" v-on:click="toggleMenu">
          <div></div>
        </div>
      </div>
      <div class="top_panel--content" v-if="isOpen">
        <div class="top_panel--content_holder">
          <div class="top_panel--menu_container">
            <div class="top_panel--menu_item">
              <a class="top_panel--menu_link" href="/about">about</a>
            </div>
            <div class="top_panel--menu_item">
              <a class="top_panel--menu_link" href="/services">services</a>
            </div>
            <div class="top_panel--menu_item">
              <a class="top_panel--menu_link" href="/contact">contact</a>
            </div>
            <div class="top_panel--menu_item">
              <a class="top_panel--menu_link" href="/privacy">privacy</a>
            </div>
          </div>
          <div class="top_panel--details_container">
            <div class="top_panel--details_holder">
              <div class="top_panel--details_name">FairCode Ltd</div>
              <div class="top_panel--address_container">
                <div class="top_panel--address_row">Kemp House, 160 City Road</div>
                <div class="top_panel--address_row">London, EC1V 2NX</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="top_panel--menu_footer" v-if="isOpen">
        <div class="top_panel--menu_footer_text">© FairCode Ltd 2021</div>
        <a class="top_panel--menu_footer_link" href="mailto:hello@faircode.tech">hello@faircode.tech</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopPanel',
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleMenu () {
      this.isOpen === true ? this.closeMenu() : this.openMenu()
    },
    openMenu () {
      this.isOpen = true
      document.body.style.overflow = 'hidden'
    },
    closeMenu () {
      this.isOpen = false
      document.body.style.overflow = 'auto'
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './top_panel';
</style>
